import { ReactComponent as EmptySVG } from '../../assets/img_emptyfile.svg';
import styles from './index.module.scss';

const NotFound = () => {
  return (
    <div className={styles.notFound}>
      <EmptySVG />
      <div className={styles.description}>
        <p>系統發生問題</p>
        <p>請重新開啟畫面或是告知門市人員</p>
      </div>
    </div>
  );
};
export default NotFound;
