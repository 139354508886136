import type { ErrorInfo, PropsWithChildren } from 'react';
import React from 'react';
import type { ErrorBoundaryProps } from 'react-error-boundary';

import { errorResponse } from '../../api/error';
import ErrorPage from '../ErrorPage';

export class ErrorBoundary extends React.Component {
  constructor(
    props: PropsWithChildren<ErrorBoundaryProps> | Readonly<PropsWithChildren<ErrorBoundaryProps>>,
  ) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    const response = {
      environment: 'frontend',
      log_level: 'error',
      log: error.stack ?? error.message,
    };

    errorResponse(response);
  }

  render() {
    // @ts-ignore
    if (this.state.errorInfo) {
      // @ts-ignore
      return <ErrorPage />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
