/**
 * Auth Context
 *
 * 使用Line ID 進行登入
 * 在此判斷會員是否已註冊過
 * 若是已註冊，API會回傳 access Token
 * 若尚未註冊，則會吐回404
 */
import { Login } from 'api';
import { getCouponWithoutToken } from 'api/coupon';
import type { MeData } from 'api/me';
import { getMe } from 'api/me';
import { useBrand } from 'context/Brand';
import { useLiff } from 'context/Liff';
import * as PropTypes from 'prop-types';
import type { Consumer, Context, FC } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

interface AuthProviderProps {
  lineToken: string;
  brandID: number;
}
interface UserData extends Partial<MeData> {
  isLoggedIn?: boolean;
  isMember?: boolean;
  isNormal?: boolean;
  accessToken?: string | null;
}
interface AuthContext {
  error?: any;
  logging: boolean;
  user?: UserData;
  auth: (params?: { autoRegister?: boolean }) => void;
  fetching: boolean;
  queryMe?: () => Promise<void>;
}
type CreateAuthContext = () => {
  AuthConsumer: Consumer<AuthContext>;
  AuthProvider: FC<AuthProviderProps>;
  useAuth: () => AuthContext;
};
const AuthProviderPropTypes = {
  children: PropTypes.element.isRequired,
  lineToken: PropTypes.string.isRequired,
  brandID: PropTypes.number.isRequired,
};

const createAuthProvider = (context: Context<AuthContext>) => {
  const AuthProvider: FC<AuthProviderProps> = ({ children, lineToken, brandID }) => {
    const [authState, setAuthState] = useState<{
      error?: unknown;
      logging: boolean;
      user?: UserData;
      fetching: boolean;
    }>({
      logging: true,
      fetching: false,
    });
    const { liff } = useLiff();
    const { brandInfo } = useBrand();
    const collectID = useMemo(() => {
      const searchParams = new URLSearchParams(window.location.search);
      return searchParams.get('collect');
    }, []);

    const getMeData = useCallback(async (token: string) => {
      try {
        const resp = await getMe(token);
        return resp.data;
      } catch (error) {
        console.log(error);
        return undefined;
      }
    }, []);

    const auth = useCallback(
      async (params?: { autoRegister?: boolean }) => {
        try {
          const { autoRegister = false } = params ?? {};

          const { error: loginError, data: resp } = await Login(lineToken, brandID, autoRegister);

          if (loginError) {
            if (loginError.response?.status === 401) {
              setAuthState((prev) => ({
                ...prev,
                error: loginError,
                user: {
                  accessToken: undefined,
                  isLoggedIn: false,
                  isNormal: false,
                  isMember: false,
                },
                logging: false,
              }));

              liff.logout();
            } else if (loginError.response?.status === 404) {
              setAuthState((prev) => ({
                ...prev,
                user: {
                  accessToken: undefined,
                  isLoggedIn: false,
                  isNormal: false,
                  isMember: false,
                },
                logging: false,
              }));
            } else {
              setAuthState((prev) => ({
                ...prev,
                error: loginError,
                user: {
                  accessToken: undefined,
                  isLoggedIn: false,
                  isNormal: false,
                  isMember: false,
                },
                logging: false,
              }));
            }
            return;
          }
          if (resp) {
            const { data } = resp;

            const meData = await getMeData(data.access_token);
            setAuthState((prev) => ({
              ...prev,
              error: undefined,
              user: {
                ...meData,
                accessToken: data.access_token,
                isLoggedIn: true,
                isNormal: data.is_common === 1,
                isMember: data.is_common === 0,
              },
              logging: false,
            }));
          }
        } catch (error) {
          setAuthState((prev) => ({
            ...prev,
            error,
            user: {
              accessToken: undefined,
              isLoggedIn: false,
              isNormal: false,
              isMember: false,
            },
            logging: false,
          }));
        }
      },
      [brandID, liff, lineToken, getMeData],
    );

    const checkCoupon = useCallback(
      async (id: string) => {
        try {
          if (brandInfo?.id) {
            const { data } = await getCouponWithoutToken(id, { brand_id: brandInfo.id });
            return data;
          }
          return undefined;
        } catch (error) {
          return undefined;
        }
      },
      [brandInfo?.id],
    );

    const fetchMe = useCallback(async (token: string) => {
      try {
        setAuthState((prev) => ({
          ...prev,
          fetching: true,
        }));
        const resp = await getMe(token);
        setAuthState((prev) => ({
          ...prev,
          error: undefined,
          user: {
            ...prev.user,
            ...resp.data,
          },
          fetching: false,
        }));
      } catch (error) {
        console.log(error);
        setAuthState((prev) => ({
          ...prev,
          fetching: false,
        }));
      }
    }, []);

    const queryMe = useMemo(() => {
      if (authState.user?.accessToken) {
        const token = authState.user?.accessToken;
        return () => fetchMe(token);
      }
      return undefined;
    }, [fetchMe, authState.user?.accessToken]);

    useEffect(() => {
      if (lineToken && brandID) {
        (async () => {
          try {
            if ((collectID !== undefined && collectID !== null) || collectID) {
              const coupon = await checkCoupon(collectID);
              if (coupon && coupon.share_type === 0) {
                await auth({ autoRegister: true });
              } else {
                await auth();
              }
            } else {
              await auth();
            }
          } catch (error) {
            console.log(error);
            setAuthState((prev) => ({
              ...prev,
              error,
            }));
          }
        })();
      } else {
        console.error('lineToken && brandID', lineToken, brandID);
      }
    }, [lineToken, brandID, collectID, checkCoupon, auth]);

    return (
      <context.Provider
        value={{
          ...authState,
          auth,
          queryMe,
        }}
      >
        {children}
      </context.Provider>
    );
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  AuthProvider.propTypes = AuthProviderPropTypes;
  return AuthProvider;
};
export const createAuthContext: CreateAuthContext = () => {
  const context = createContext<AuthContext>({
    logging: true,
    auth: () => {},
    fetching: false,
    user: undefined,
  });
  context.displayName = 'AuthContext';

  return {
    AuthConsumer: context.Consumer,
    AuthProvider: createAuthProvider(context),
    useAuth: () => useContext(context),
  };
};
export const { AuthConsumer, AuthProvider, useAuth } = createAuthContext();
